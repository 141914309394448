<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-14 14:49:23
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-12-17 23:28:34
-->
<template>
  <div class="bg">
    <div class="logo"></div>
    <div class="form">
      <div class="row row--user">
        <input type="number" placeholder="请输入手机号" v-model="mobile" />
      </div>
      <div class="row row--pwd">
        <input type="password" placeholder="请输入密码" v-model="password" />
      </div>
      <button class="login" @click="login">登录</button>
      <div class="flex-x center between mini-btn">
        <div @click="$router.push({ name: 'ServiceRegister' })">新用户注册</div>
        <div @click="$router.push({ name: 'ServiceForget' })">忘记密码</div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "../../../utils/api/utils";
import { Toast, Dialog } from "vant";
export default {
  data() {
    return {
      mobile: "",
      password: "",
    };
  },
  methods: {
    login() {
      login({
        mobile: this.mobile,
        password: this.password,
      })
        .then((res) => {
          let isReview = res.data.data.isReview;
          // 审核状态
          switch (isReview) {
            case 0:
              return Toast("账号审核中");
            case 1:
              window.localStorage.setItem("token", res.data.data.token);
              this.$router.push({
                name: "ServiceIndex",
              });
              break;
            case 2:
              return Dialog.alert({
                title: "审核失败",
                message: res.data.data.reason,
                confirmButtonColor: "#3c77e4",
              });
          }
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },
  },
};
</script>

<style src="@/assets/css/review/login.css" scoped>
</style>